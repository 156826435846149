/**
 * @prettier
 */
import { UIButton, UIIcon, H1 } from 'talkable-ui-kit'
import styled from 'styled-components'
import { useEffect, useRef, useState, FC } from 'react'

const PopupContainer = styled.div<{ maxHeight: number; width: number }>`
  position: absolute;
  top: 0;
  right: 0;
  background-color: white;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
  width: ${({ width }): string => `${width}px`};
  max-height: ${({ maxHeight }): number => maxHeight}px;
  height: fit-content;
  overflow-y: auto;
  z-index: 1000;
`

const HeaderContent = styled.div`
  position: sticky;
  top: 0;
  background-color: white;
  padding: 22px 16px 16px 35px;
  z-index: 10;
`

const CloseButton = styled.button`
  position: absolute;
  top: 22px;
  right: 16px;
  background: none;
  border: none;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`

const PopupContent = styled.div`
  padding: 16px 16px 22px 35px;
  overflow-x: auto;
`

const ButtonWrapper = styled.div`
  display: inline-block;
  position: relative;
`

const Container = styled.div`
  position: relative;
`

export const SegmentsModal: FC<{ __html: string; width: number }> = ({
  __html,
  width,
}) => {
  const [maxHeight, setMaxHeight] = useState(500)
  const containerRef = useRef<HTMLDivElement | null>(null)
  const popupRef = useRef<HTMLDivElement | null>(null)

  const [isOpen, setIsOpen] = useState<boolean>(() => {
    const params = new URLSearchParams(window.location.search)

    return params.get('segmentsModalOpen') === 'true'
  })

  const handleToggle = (): void => {
    const newOpen = !isOpen

    setIsOpen(newOpen)

    const url = new URL(window.location.href)

    if (newOpen) {
      url.searchParams.set('segmentsModalOpen', 'true')
    } else {
      url.searchParams.delete('segmentsModalOpen')
    }

    window.history.replaceState({}, '', url.toString())
  }

  useEffect(() => {
    if (isOpen && containerRef.current) {
      const updateMaxHeight = (): void => {
        const containerTop = containerRef.current!.getBoundingClientRect().top
        const viewportHeight = window.innerHeight

        setMaxHeight(viewportHeight - containerTop)
      }

      requestAnimationFrame(updateMaxHeight)
      window.addEventListener('resize', updateMaxHeight)

      return (): void => {
        window.removeEventListener('resize', updateMaxHeight)
      }
    }

    return undefined
  }, [isOpen])

  useEffect(() => {
    if (!isOpen) return

    const handleSegmentClick: EventListener = event => {
      const target = event.target as HTMLElement
      const el = target.closest('.segment-option') as HTMLElement | null

      if (!el) return
      const href = el.dataset.href

      if (!href) return
      const url = new URL(href, window.location.origin)

      url.searchParams.set('segmentsModalOpen', 'true')
      window.location.href = url.toString()
    }

    const clickableItems = document.querySelectorAll('.segment-option')

    clickableItems.forEach(el => el.addEventListener('click', handleSegmentClick))

    return (): void => {
      clickableItems.forEach(el => el.removeEventListener('click', handleSegmentClick))
    }
  }, [isOpen])

  return (
    <Container ref={containerRef}>
      <ButtonWrapper>
        <UIButton trait="ghost" size="small" onClick={handleToggle} disabled={isOpen}>
          Analyze by segment
        </UIButton>
      </ButtonWrapper>

      {isOpen && (
        <PopupContainer
          ref={popupRef}
          maxHeight={maxHeight}
          width={width || 600}
          id="possible-segments"
        >
          <HeaderContent>
            <H1>Analyze by segment</H1>
            <CloseButton onClick={handleToggle}>
              <UIIcon name="cross" />
            </CloseButton>
          </HeaderContent>

          <PopupContent>
            <div dangerouslySetInnerHTML={{ __html }} />
          </PopupContent>
        </PopupContainer>
      )}
    </Container>
  )
}
